import React from 'react'
import { Paragraph } from './utils'
import { Population_CA_County } from './graphs/population_county_map_graph'
import { Population_CA_City } from './graphs/population_city_map_graph'
import bay_area_city from '../assets/json/bay_area_city_population.json'
import { ResponsiveBar } from '@nivo/bar'
import Header from '../components/Header'

const p1 =
  '美国作为一个移民国家，人口的多元化一直存在与这个国家的基因里。如果说建国时的美国还只是盎格鲁-撒克逊人主导的英国殖民地，那么随着之后两个世纪欧洲，非洲，亚洲移民的纷沓而至，二十世纪后半叶的美国已经成为了一个全球文化的大熔炉。这种多种族的底蕴既让“美国人”这个身份认同变得复杂，也让每一个生活在这片土地上的人同时感受到机遇和挑战，不确定性和活力，以及社会和自我的相互碰撞。而加州作为美国面向太平洋的门户，更是把这种国际和多元的特质发挥得淋漓尽致。下面我们就用数据说话，对湾区的人口情况一探究竟。'

const p2 =
  '湾区作为一个广义概念，其具体范围其实没有官方的定论。本文中所讨论的湾区包括San Francisco，San Mateo，Santa Clara，Alameda四个郡（如下图所示）。'

const p3 =
  '湾区总人口大概五百万左右。其中圣何塞和旧金山两个一线城市里面，按人口数量来说，圣何塞才是湾区的老大，而旧金山作为世界文化的KOL其实一百万人都没有。当然，城市影响力比的不光是人数，否则湾区城市平均十万人的水平在中国也就能算个镇。与美国其它很多地方不一样的是，在湾区，一个城市人烟稀少不代表它荒凉，而往往代表它很有钱。接下来重点看看有湾区的人口组成特色。'

const p4 =
  '很多从美国其它州来湾区玩的小伙伴都会感叹湾区太不美国了，这是有道理的。如果你在美国中西部，见到一个中国人大概会老乡见老乡两眼泪汪汪；如果你在Cupertino的鲜芋仙喝奶茶，从扎堆的老中老印里好不容易见到一个白人，大概才会想起来哦原来我还是在美国。湾区亚洲人多固然肉眼可见，但是Cupertino的亚裔超过70%这一事实还是让我吓了一跳，毕竟亚裔比例在美国全国只有5%（湾区整体亚裔占比30%）。在湾区的亚裔不仅不再是少数群体，而是对地区发展和文化建设都扮演着举足轻重的角色的群体。我想这大概也是为什么在湾区的亚裔会比美国其它地方的亚裔更有归属感，更有主人翁精神，因为人数上的底气就摆在那里。'

const p5 =
  '以上两张图里Cupertino和Milpitas同时占据了湾区城市移民和亚裔人口占比的前两位宝座，这大概也不是偶然，因为最近这些年硅谷的移民大多的确来源于亚裔。硅谷向来热衷于追捧那些改变世界的科技领袖，但是科技的进步永远靠的不是灵光一现，而是大量基层人才的持续辛勤付出。没有印度和中国这些年为硅谷输送的大量新鲜血液（突然想起了一百多年前修建美国横贯大陆铁路的华工血泪史），硅谷大佬们就算有三头六臂，估计也无法达到今天的高度。而在今天的湾区，南湾则是中国和印度裔移民的最爱，不仅科技公司扎堆，成熟的亚裔社区也让初来乍到的新硅谷人有宾至如归的感觉。东湾最近几年发展势头比较迅猛，虽然离科技中心稍有距离，但是其更合理的房价还是受到了南湾不少科技人才的青睐；而半岛则还是悠闲地坐稳老牌贵族的主阵地，毕竟资本和打工人还是要保持距离。更多细节可以用下面的地图分城市查看。'

const p6 =
  '最后说一句题外话，近期AI的火热让不少人都开始担心未来是不是有一天许多人会因此失业。这个问题大概很难给出一个明确答案，但是湾区的历史告诉我们，人类的坚韧性和可塑性远比想象的要强。从淘金潮到互联网，一拨又一拨才华横溢的弄潮儿怀揣着希望来到湾区，有的走向辉煌，有的黯然离去。多年以后，AI浪潮必然又会成为历史上的光辉一页，其中究竟当然无法预料，但是在这片浪潮中无数男男女女挥洒过的青春，追逐过的梦想，就会像太平洋里的浪花，拍打着岸边，永远荡气回肠。'


export default function Population({ post_id }) {
  return (
    <>
      <Header />

      <div className="w3-margin-top w3-row-padding article">
        <div className="w3-twothird w3-margin-bottom">
          <h3>湾区闲聊之人口</h3>
          <blockquote className="w3-panel w3-leftbar w3-light-grey">
            <p className="w3-small">
              <i>"I say to you today, my friends, so even though we face the difficulties of today and tomorrow, I still
                have a dream."</i></p>
            <p className="w3-small">Martin Luther King, Jr.</p>
          </blockquote>
          <Paragraph key={1}
                     text={p1} />
          <Paragraph key={2}
                     text={p2} />
          <div>
            <Population_CA_County />
          </div>
          <Paragraph key={3}
                     text={p3} />
          <PopulationBarChart data={cityPopulationData()}
                              title={'湾区主要城市人口汇总（单位：万）'}
                              color={'#FF7F7F'}
                              column={'Population'} />
          <Paragraph key={4}
                     text={p4} />
          <PopulationBarChart data={immigrationData()}
                              title={'湾区主要城市移民人口百分比'}
                              color={'#cce4f2'}
                              column={'Immigrant Percentage'} />
          <br />
          <PopulationBarChart data={asianData()}
                              title={'湾区主要城市亚裔人口百分比'}
                              color={'#FAEBD7'}
                              column={'Asian Percentage'} />
          <Paragraph key={5}
                     text={p5} />
          <div>
            <Population_CA_City />
          </div>
          <Paragraph key={6}
                     text={p6} />
          <br />
          <br />
          <span><b>数据来源</b></span>
          <ul>
            <li>数据美国, <a href={'https://datausa.io/'}>https://datausa.io/</a></li>
            <li>维基百科, <a href={'https://www.wikipedia.org/'}>https://www.wikipedia.org/</a></li>
          </ul>
        </div>
      </div>
    </>
  )
}

const asianData = () => {
  const converted = bay_area_city.features.map(feature => {
    const ad = {}
    ad['name'] = feature['name'].toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
    const res = feature['stats']['DEMOGRAPHICS']['ASIAN']
    ad['Asian Percentage'] = res.substring(0, res.length - 1)
    return ad
  })
  converted.sort((a, b) => (a['Asian Percentage'] - b['Asian Percentage']))
  return converted
}

const immigrationData = () => {

  const converted = bay_area_city.features.map(feature => {
    const ad = {}
    ad['name'] = feature['name'].toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
    const res = feature['stats']['FOREIGN-BORN POPULATION']
    ad['Immigrant Percentage'] = res.substring(0, res.length - 1)
    return ad
  })
  converted.sort((a, b) => (a['Immigrant Percentage'] - b['Immigrant Percentage']))
  return converted
}

const cityPopulationData = () => {

  const converted = bay_area_city.features.map(feature => {
    const ad = {}
    ad['name'] = feature['name'].toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
    var res = feature['stats']['POPULATION'].replace(/,/g, '')
    if (res[res.length - 1] === 'M') {
      res = (parseInt(res.substring(0, res.length - 1)) * 1000000).toString()
    }
    ad['Population'] = Math.round(parseInt(res) / 10000)
    return ad
  })
  converted.sort((a, b) => (a['Population'] - b['Population']))
  return converted
}

const PopulationBarChart = ({ data, title, color, column }) => {
  return (
    <div className={'nivo-container'} style={{ height: '400px' }}>
      <span>{title}</span>
      <ResponsiveBar data={data}
                     layout={'horizontal'}
                     keys={[column]}
                     indexBy="name"
                     margin={{
                       top: 5,
                       right: 5,
                       bottom: 5,
                       left: 80
                     }}
                     colors={[color]}
                     enableGridY={false}
                     axisBottom={null}
                     axisLeft={{
                       tickSize: 0,
                       tickPadding: 5,
                       tickRotation: 0
                     }} />
    </div>
  )
}