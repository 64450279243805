import React, { useEffect, useState } from 'react'
import 'w3-css/w3.css'
import '../App.css'
import userService from '../services/user'
import '@react-sigma/core/lib/react-sigma.min.css'
import { Notification } from '../components/utils'
import { LoadGraphWithProp } from './Sigma'
import Header from '../components/Header'
import { Chat } from './WebRTC'

const Dashboard = ({ handleLogout }) => {
  let dd = 3
  return (
    <>
      <h1>Admin Console</h1>
      <button onClick={handleLogout}>Log out</button>
    </>
  )
}

const LoginCard = ({ handleTokenChange }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const handleLogin = (event) => {
    event.preventDefault()
    console.log('logging in with', username, password)
    const credentials = {
      name: username,
      password: password
    }
    userService
      .login(credentials)
      .then(response => {
        handleTokenChange(response.data['token'])
      })
      .catch(error => {
        console.log(`the error is ${error}`)
        setErrorMessage('Wrong password')
      })
    setUsername('')
    setPassword('')
  }

  const loginForm = () => (
    <div className={'w3-card login-card w3-round'}>
      <form onSubmit={handleLogin}>
        <p className={'w3-padding'}>
          <label><b>Username</b></label>
          <input
            className={'w3-input w3-border w3-round'}
            type="text"
            value={username}
            name="Username"
            onChange={({ target }) => setUsername(target.value)}
          />
        </p>
        <p className={'w3-padding'}>
          <label><b>Password</b></label>
          <input
            className={'w3-input w3-border w3-round'}
            type="password"
            value={password}
            name="Password"
            onChange={({ target }) => setPassword(target.value)}
          />
        </p>
        <div className={'w3-padding'}>
          <button className={'w3-btn w3-green'} type="submit">login</button>
        </div>
      </form>
    </div>
  )

  return (
    <>
      {errorMessage !== null && <Notification message={errorMessage} />}
      {loginForm()}
    </>
  )
}

const Admin = () => {
  const [token, setToken] = useState(null)

  const retrieveToken = () => {
    let tt = window.localStorage.getItem('token')
    setToken(tt)
  }

  const handleLogin = (newToken) => {
    window.localStorage.setItem('token', newToken)
    console.log(`successfully created token ${newToken}`)
    setToken(window.localStorage.getItem('token'))
  }

  useEffect(retrieveToken, [])

  const handleLogout = () => {
    userService
      .logout()
      .then(response => {
        console.log(`successfully revoked token ${response.data}`)
      })
      .catch(error => {
        console.log(`token already expired but logging out anyways`)
      })
    window.localStorage.removeItem('token')
    setToken(null)
  }


  return (
    <>
      <Header />
      <div className="w3-margin-top w3-row-padding">
        <div className={'w3-display-container'}>
          <div>
            <div>
              {token === null && <LoginCard handleTokenChange={handleLogin} />}
              {token !== null && <Dashboard handleLogout={handleLogout} />}
            </div>
          </div>
        </div>
      </div>
      <CA_County />
    </>
  )
}

export default Admin
