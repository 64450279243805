import React, { useEffect, useState } from 'react'
import commentService from '../services/comment'
import 'w3-css/w3.css'
import './comment.css'
import { convertTimestampToRelativeTime } from './utils'

const commentOrderComparator = (a, b) => {
  return b['timestamp'] - a['timestamp']
}

const CommentDialog = ({ handleSubmit }) => {
  const [initialTextColor, setInitialTextColor] = useState(true)
  const [newComment, setNewComment] = useState('   欢迎留言哟')
  const handleCommentGroupChange = (event) => {
    setNewComment(event.target.value)
    setInitialTextColor(false)
  }

  const handleCommentGroupButtonFocusChange = () => {
    if (initialTextColor) {
      setInitialTextColor(false)
      setNewComment('')
    }
  }
  const submitComment = (event) => {
    event.preventDefault()
    console.log(`enterComment ${newComment}`)
    setInitialTextColor(true)
    handleSubmit(newComment)
    setNewComment('')
  }

  return (
    <div>
      <form onSubmit={submitComment}>
        <div>
          <textarea
            className={initialTextColor ? 'comment-dialog-input-initial' : 'comment-dialog-input-user'}
            value={newComment}
            onChange={handleCommentGroupChange}
            onFocus={handleCommentGroupButtonFocusChange}
          />
        </div>
        <button className={'comment-submit w3-button w3-round w3-small'}>提交</button>
      </form>
    </div>
  )
}

const Comment = ({ author, timestamp, content, allowReply, handleNewComment }) => {
  const [replyClicked, setReplyClicked] = useState(false)
  const handleReplyButtonClick = () => {
    console.log(`in handleReplyButtonClick`)
    setReplyClicked(!replyClicked)
  }

  const handleSubmitComment = (comment) => {
    setReplyClicked(false)
    handleNewComment(comment)
  }

  return (
    <div className={'w3-margin-top'}>
      <div className={'comment-avatar'}>
        <img src={'https://www.gravatar.com/avatar'} alt={''} />
      </div>

      <div className={'comment-body'}>
        <div className={'comment-author'}>
          {author}
        </div>

        <div className={'comment-content'}>
          {content}
        </div>
        <div className={'comment-timestamp'}>
          <span>{convertTimestampToRelativeTime(timestamp)}</span>
          {allowReply &&
            <button className={'reply'} onClick={handleReplyButtonClick}>回复</button>
          }
        </div>
        {replyClicked &&
          <CommentDialog
            handleSubmit={handleSubmitComment}
          />}
      </div>
    </div>
  )
}

const CommentGroup = ({ commentGroup }) => {
  const [comments, setComments] = useState(commentGroup.children)
  const constructNewComment = (content) => {
    const commentObject = {
      content: content,
      timestamp: Date.now(),
      post_id: 1,
      comment_group_id: commentGroup.comment_group_id
    }

    commentService
      .create(commentObject)
      .then(response => {
        console.log(`the newly loaded comment group is back`)
        // load the only comment group in the array
        let latestComments = response.data[0].children
        latestComments.sort(commentOrderComparator)
        setComments(latestComments)
      })
  }

  return (
    <div>
      <Comment
        key={commentGroup.comment_id}
        author={'匿名用户'}
        timestamp={commentGroup.timestamp}
        content={commentGroup.content}
        allowReply={true}
        handleNewComment={constructNewComment}

      />
      <ul>
        {comments.map(comment =>
          <Comment
            key={comment.comment_id}
            author={'匿名用户'}
            timestamp={comment.timestamp}
            content={comment.content}
            allowReply={false}
            handleNewComment={constructNewComment}
          />
        )}
      </ul>
    </div>
  )
}
const CommentBoard = (props) => {

  const [commentGroups, setCommentGroups] = useState([])

  const hook = () => {
    commentService
      .get_by_post_id(props.post_id)
      .then(response => {
        const commentGroups = response.data
        commentGroups.sort(commentOrderComparator)
        setCommentGroups(commentGroups)
      })
  }
  useEffect(hook, [])

  const constructNewCommentGroup = (content) => {
    const commentObject = {
      content: content,
      timestamp: Date.now(),
      post_id: props.post_id
    }

    commentService
      .create(commentObject)
      .then(response => {
        hook()
        console.log(`created new comment group with id ${response.data.comment_id}`)
      })
  }


  return (
    <div className={'w3-container comment-board w3-twothird '}>
      <div>
        <div className={'comment-avatar'}>
          <img src={'https://www.gravatar.com/avatar'} alt={''} />
        </div>
        <div className={'comment-body'}>
          <CommentDialog
            handleSubmit={constructNewCommentGroup}
          />
        </div>
      </div>

      <hr />

      <div>
        <ul className={'w3-ul no-bullets'}>
          {commentGroups.map(commentGroup =>
            <CommentGroup
              key={commentGroup.comment_id}
              commentGroup={commentGroup}
            />
          )}
        </ul>
      </div>
    </div>
  )
}

export default CommentBoard