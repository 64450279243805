import axios from 'axios'

// const baseUrl = 'https://api.totheloners.com/comments'
// const baseUrl = 'http://127.0.0.1:5000/comments'

const baseUrl = process.env['REACT_APP_COMMENT_BASE_URL']

const get_by_post_id = (post_id) => {
  return axios.get(baseUrl + `/${post_id}`)
}

const create = newObject => {
  return axios.post(baseUrl, newObject)
}

const get_by_comment_group = (post_id, comment_group_id) => {
  return axios.get(baseUrl + `/${post_id}/${comment_group_id}`)
}

export default { get_by_post_id, create, get_by_comment_group }