import { useRef, useState } from 'react'
import bay_area_county from '../../assets/json/bay_area_county_population.json'
import countyCA from '../../assets/json/California_Counties.json'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'
import sf from '../../assets/images/san_francisco.webp'
import burlingame from '../../assets/images/burlingame.webp'
import stanford from '../../assets/images/stanford.webp'
import oakland from '../../assets/images/oakland.webp'
import './graphs.css'


const getCountyColor = (countyName) => {
  switch (countyName) {
    case 'Santa Clara County':
      return '#800026'
    case 'Marin County':
      return '#0390fc'
    case 'San Francisco County':
      return '#039dfc'
    case 'San Mateo County':
      return '#fc03fc'
    default:
      return '#FD8D3C'
  }
}
const getCountyDecor = (feature) => {
  return {
    fillColor: getCountyColor(feature.properties.NAME),
    weight: 2,
    opacity: 1,
    color: 'white',
    dashArray: '3',
    fillOpacity: 0.7
  }
}

const getCountyPictureURL = (name) => {
  switch (name) {
    case 'San Francisco County':
      return sf
    case 'San Mateo County':
      return burlingame
    case 'Santa Clara County':
      return stanford
    case 'Alameda County':
      return oakland
    default:
      return 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg'

  }
}

const CountyInfoPanel = ({ feature, featureMap }) => {
  return (

    <div className={'leaflet-top leaflet-right'}>
      <div className="leaflet-control leaflet-bar population-county-graph-info-panel">
        {feature === null && <p style={{ textAlign: 'center' }}>详情点击染色区域</p>}
        {feature !== null && <CountyInfo feature={feature} featureMap={featureMap} />}
      </div>
    </div>
  )
}

const CountyInfo = ({ feature, featureMap }) => {
  const placeName = feature.properties.NAME
  const imgURL = getCountyPictureURL(placeName)
  return (
    <div>
      <div>
        <b>{placeName}</b> <br /> <br />
        <div>
          <img className={'leaflet-county-img'} src={imgURL} alt={'nice job'} />
        </div>
      </div>
    </div>
  )
}

const featureMap = new Map()
bay_area_county.features.forEach(f => featureMap.set(f.name, f.stats))
const bay_area_county_list = bay_area_county.features.map(f => f['name'])
const filteredCounties = {
  ...countyCA, features: countyCA.features.filter(f => {
    return bay_area_county_list.indexOf(f.properties.NAME.toLowerCase()) > -1
  })
}

const Population_CA_County = () => {
  const anchorPosition = [37.5488, -121.9683]

  const geoRef = useRef()
  const [countyFeature, setCountyFeature] = useState(null)

  const resetHighlight = (geo, layer) => {
    geo.current.resetStyle(layer)
    setCountyFeature(null)
  }
  const highlightFeature = (e, feature) => {
    const layer = e.target

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    })
    layer.bringToFront()
    setCountyFeature(feature)
  }

  const inter = (feature, layer) => {
    layer.on({
      mouseover: (e) => highlightFeature(e, feature),
      mouseout: () => resetHighlight(geoRef, layer)
    })
  }

  return (
    <div className={'leaflet-map-container'}>
      <MapContainer center={anchorPosition} zoom={8} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON ref={geoRef} key={'my-geo'} data={filteredCounties} style={getCountyDecor}
                 onEachFeature={inter} />
        <CountyInfoPanel feature={countyFeature} featureMap={featureMap} />
      </MapContainer>
    </div>
  )
}

export { Population_CA_County }