import React from 'react'
import 'w3-css/w3.css'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <div className={'w3-top w3-bar w3-white w3-padding w3-card w3-wide w3-margin-bottom'}>
        <Link to="/" className="w3-bar-item w3-button">Patrick Zeng's Blog</Link>
        <Link to="/chatroom" className="w3-bar-item w3-button">Chat Room</Link>
        <Link to="/file_upload" className="w3-bar-item w3-button">File Uploader</Link>
      </div>

      <br />
      <br />
    </>
  )
}

export default Header