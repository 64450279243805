import React from 'react'
import 'w3-css/w3.css'
import { Link } from 'react-router-dom'
import './App.css'
import AZ from './assets/images/AZP.webp'
import Header from './components/Header'
import logisticsService from './services/logistics'

function App() {
  logisticsService.logVisit()
  return (
    <>
      <Header />

      <div id="home" className="w3-margin-top w3-row-padding">
        <div className="w3-third w3-container">
          <img src={AZ} alt="Arizona" />
        </div>
        <div className="w3-third w3-panel w3-light-grey">
          <p className="w3-xlarge w3-serif">
            <i>"Sunnyvale是个好地方，随时都可以离开她，但你就是没有."</i></p>
        </div>
      </div>

      <div id="articles" className="w3-margin-top w3-row-padding">
        <div className="w3-twothird w3-container">
          <ul className="w3-ul w3-card-4 no-bullets w3-hoverable">
            <li>
              <Link to="dating">湾区闲聊之找对象</Link>
            </li>
            <li>
              <Link to="population">湾区闲聊之人口</Link>
            </li>
            <li>
              <Link to="housing">湾区闲聊之买房</Link>
            </li>
            <li>
              <Link to="cali_history">加州闲聊之历史</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default App