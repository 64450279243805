import { Link } from 'react-router-dom'
import React, { useRef, useState } from 'react'
import { Paragraph } from './utils'
import { CA_City_With_Boundaries, GrowthLegend } from './graphs/housing_city_map_graph'
import price_svg from '../assets/images/price.svg'
import growth_svg from '../assets/images/growth.svg'
import Header from '../components/Header'

const p1 =
  '最近有一个比较有意思的数据：美国的首次置业平均年龄为36岁，而中国则是27岁。换句话说，中国的年轻人买第一套房比美国的年轻人早了几乎十年。自己现在也进入而立之年，身边越来越多的朋友同事要么已经置业，要么在看房的路上。回想起来当年住在纽约的时候，觉得买房好像是一件很遥远的事情。一来是当时自己刚毕业，再者是纽约的都市白领似乎更喜欢租房的自由。相比之下，湾区的家庭气氛相对浓厚一些，想要进入稳定的家庭生活买房也是顺理成章。大家都知道湾区的房子贵，那我们就先看看到底有多贵。'
const p2 =
  '的确是贵。作为湾区中产阶级的大众梦中情人，Palo Alto的房子中位数价格已经接近三百五十万。要知道曼哈顿也不过一百三十万，甚至在湾区都只能排倒数。湾区整体平均一百八十万的房价来说，坐上美国的第一把交椅基本没有什么问题。按照一套房子平均1400尺的大小来算，一百八十万美元的房子换算成人民币每平米正好是十万，大概和北京海淀或者上海静安区持平。'
const p3 =
  '这几年房价的飙升让不少小伙伴都开始焦虑，是不是再不买就上不了车了。下面我们来看看过去一年房价的变化。'

const p4 =
  '即便房贷继续徘徊在7%的高位，大部分城市都有10%以上的增幅，不得不说现金湾区的房市仍然是卖方市场，难怪大家都有买房的焦虑。疫情以来美联储的印钞的威力大家实打实的感受到了，房市股市以及麦当劳汉堡的价格都在直线上升。'
const p5 =
  '最后以一个问题收尾。在湾区买一套房子的钱基本可以在世界上任何一个地方买到房子，如果任你挑，你最想买哪里？'

export default function Housing({ post_id }) {
  return (
    <>
      <Header />

      <div className="w3-margin-top w3-row-padding article">
        <div className="w3-twothird w3-margin-bottom">
          <h3>湾区闲聊之买房</h3>
          <blockquote className="w3-panel w3-leftbar w3-light-grey">
            <p className="w3-small">
              <i>"You are not buying a house, you are buying a lifestyle."</i></p>
            <p className="w3-small">Anonymous</p>
          </blockquote>
          <Paragraph key={1}
                     text={p1} />
          <div className={'w3-card'}>
            <img src={price_svg} alt={'median price chart'} />
          </div>
          <div>
            <CA_City_With_Boundaries priceOrGrowth={1} />
          </div>
          <Paragraph key={2}
                     text={p2} />
          <Paragraph key={3}
                     text={p3} />
          <div className={'w3-card'}>
            <img src={growth_svg} alt={'growth chart'} />
          </div>
          <Paragraph key={4}
                     text={p4} />
          <CA_City_With_Boundaries priceOrGrowth={2} />
          <Paragraph key={5}
                     text={p5} />
          <br />
          <div>
            <span><b>数据来源</b></span>
            <ul>
              <li>Redfin, <a href={'https://www.redfin.com/'}>https://www.redfin.com/</a></li>
              <li>腾讯, <a
                style={{ 'word-wrap': 'break-word' }}
                href={'https://new.qq.com/rain/a/20230412A07VUG00'}>https://new.qq.com/rain/a/20230412A07VUG00</a></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}