import React, { useEffect, useRef, useState } from 'react'
import logisticService from '../services/logistics'
import { io } from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'

// const sockio_url = 'https://api.totheloners.com'
// const sockio_url = '127.0.0.1:5001'
const sockio_url = process.env['REACT_APP_SOCKET_IO_URL']
const socket = io(sockio_url)
const FileUploader = () => {
  const [file, setFile] = useState('')
  const [status, setStatus] = useState('initial')
  const fileRef = useRef()
  const [fileList, setFileList] = useState([])
  const [isConnected, setIsConnected] = useState(socket.connected)

  useEffect(() => {
    socket.on('connect', (socket) => {
      console.log('server connected')
    })
    socket.on('disconnect', () => {
      console.log('server disconnected')
    })
    socket.on('file_name', (value) => {
      const fileObject = { id: uuidv4(), name: value }
      setFileList(fileList => [...fileList, fileObject])
      console.log(`received file name ${value}!`)

    })
    socket.emit('join', { room: 'room2' })
  }, [])

  const handleUploadFileChange = (e) => {
    setStatus('initial')
    setFile(e.target.files[0])
  }

  const handleUploadFileSubmit = (e) => {
    e.preventDefault()
    if (file == null) {
      alert('please select a file to upload')
    } else {
      setStatus('uploading')
      const formData = new FormData()
      formData.append('file', file)
      logisticService.uploadFile(formData).then(response => {
        setStatus('success')
        console.log(`${file} and file name ${file.name}`)
        socket.timeout(5000).emit('file_name', { file_name: file.name, room: 'room2' })
      }).catch(e => {
        setStatus('fail')
      })
      setFile(null)
      fileRef.current.value = ''
      console.log(`already uploaded`)
      setTimeout(() => setStatus('initial'), 5000)
    }
  }

  const handleFileDownloadRequest = (fileRequestName) => {
    logisticService.downloadFile(fileRequestName)
      .then(response => {
        // Create blob link to download
        const fileBlob = new Blob([response.data], { type: 'image/jpeg' })
        const url = window.URL.createObjectURL(
          fileBlob
        )
        console.log(`the size of the blob is ${fileBlob.size}`)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          fileRequestName
        )

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }

  return (
    <>
      <div>
        <form onSubmit={handleUploadFileSubmit}>
          <h1>File Uploader</h1>
          <input type="file" onChange={handleUploadFileChange} ref={fileRef} />
          <button type="submit" style={{ 'display': 'inline' }}>Upload</button>
          <Result status={status} />
        </form>
        <ul>
          {fileList.map(fileObject => {
            return <li key={fileObject.id}><a
              onClick={() => handleFileDownloadRequest(fileObject.name)}>{fileObject.name}</a></li>
          })}
        </ul>
      </div>
    </>
  )
}

const Result = ({ status }) => {
  if (status === 'success') {
    return <span>✅ File uploaded successfully!</span>
  } else if (status === 'fail') {
    return <span>❌ File upload failed!</span>
  } else if (status === 'uploading') {
    return <span>⏳ Uploading selected file...</span>
  } else {
    return null
  }
}

export default FileUploader