import React, { useRef, useState } from 'react'
import bay_area_city from '../../assets/json/bay_area_city_population.json'
import incorporatedCityCA from '../../assets/json/California_Incorporated_Cities.json'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'

const medianPriceColorBins = ['#ad03fc', '#030bfc', '#328ba8', '#a86b32', '#fc3103']
const medianPriceBins = ['<$1M', '$1M-1.5M', '$1.5M-2M', '$2M-2.5M', '>$2.5M']

const growthColorBins = ['#ad03fc', '#030bfc', '#328ba8', '#a86b32', '#fc3103']
const growthBins = ['<0%', '0%-10%', '10%-20%', '%20-30%', '>30%']
const CityMedianPriceInfo = ({ feature, featureMap }) => {
  const placeName = feature ? feature.properties.CITY : ''
  var medianPrice = feature ? featureMap.get(placeName.toLowerCase())['REDFIN MEDIAN SALE PRICE'] : 'Price'
  var fontColor = 'black'
  if (feature) {
    medianPrice = parseInt(medianPrice.substring(1))
    fontColor = getMedianPriceColor(medianPrice)
    medianPrice = '$' + medianPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return (
    <div style={{ color: fontColor }}>
      {feature !== null && <span>{placeName}: </span>}
      {feature === null && <b>{'详情点击染色区域'}</b>}
      {feature !== null && <span><b>{medianPrice}</b></span>}
    </div>
  )
}

const CityPriceGrowthInfo = ({ feature, featureMap }) => {
  const placeName = feature ? feature.properties.CITY : ''
  var priceGrowth = feature ? featureMap.get(placeName.toLowerCase())['REDFIN MEDIAN SALE PRICE Growth'] : 'Price'
  var fontColor = 'black'
  if (feature) {
    priceGrowth = parseFloat(priceGrowth.substring(0, priceGrowth.length - 1))
    fontColor = getGrowthColor(priceGrowth)
    priceGrowth = priceGrowth.toString() + '%'
  }
  return (
    <div style={{ color: fontColor }}>
      {feature !== null && <span>{placeName}: </span>}
      {feature === null && <b>{'详情点击染色区域'}</b>}
      {feature !== null && <span><b>{priceGrowth}</b></span>}
    </div>
  )
}

const CityInfoPanel = (props) => {
  return (
    <div className={'leaflet-top leaflet-right'}>
      <div className="leaflet-control leaflet-bar housing-city-map-info-panel">
        {props.children}
      </div>
    </div>
  )
}

const GrowthLegend = () => {
  return (
    <IncorporatedCityColorLegend colorBins={medianPriceColorBins} valueBins={medianPriceBins} />
  )
}

const PriceLegend = () => {
  return (
    <IncorporatedCityColorLegend colorBins={medianPriceColorBins} valueBins={medianPriceBins} />
  )
}

const IncorporatedCityColorLegend = ({ colorBins, valueBins }) => {
  const colorArrayIndex = [0, 1, 2, 3, 4]
  return (
    <div style={{ margin: '10px 10px 20px 0' }}>

      <div className="houseing-city-map-legend">
        {
          colorArrayIndex.map(index => <ColorBrick key={index} backgroundColor={colorBins[index]}
                                                   annotationText={valueBins[index]} />)
        }
      </div>
    </div>
  )
}

const getGrowthColor = (growth) => {
  if (growth < 0)
    return growthColorBins[0]
  else if (growth < 10.0)
    return growthColorBins[1]
  else if (growth < 20.0)
    return growthColorBins[2]
  else if (growth < 30.0)
    return growthColorBins[3]
  else
    return growthColorBins[4]
}

const getMedianPriceColor = (medianPrice) => {
  if (medianPrice < 1000000)
    return medianPriceColorBins[0]
  else if (medianPrice < 1500000)
    return medianPriceColorBins[1]
  else if (medianPrice < 2000000)
    return medianPriceColorBins[2]
  else if (medianPrice < 2500000)
    return medianPriceColorBins[3]
  else
    return medianPriceColorBins[4]
}

const ColorBrick = ({ backgroundColor, annotationText }) => {
  return (
    <>
      <i style={{ background: backgroundColor }} />
      <b>{annotationText}</b>
      <br />
    </>
  )
}

const medianPriceDecor = (feature) => {
  const placeName = feature.properties.CITY.toLowerCase()
  var medianPrice = featureMap.get(placeName)['REDFIN MEDIAN SALE PRICE']
  medianPrice = parseInt(medianPrice.substring(1))
  return {
    fillColor: getMedianPriceColor(medianPrice),
    weight: 2,
    opacity: 1,
    color: 'white',
    dashArray: '3',
    fillOpacity: 0.7
  }
}

const priceGrowthDecor = (feature) => {
  const placeName = feature.properties.CITY.toLowerCase()
  var priceGrowth = featureMap.get(placeName)['REDFIN MEDIAN SALE PRICE Growth']
  priceGrowth = parseFloat(priceGrowth.substring(0, priceGrowth.length - 1))
  return {
    fillColor: getGrowthColor(priceGrowth),
    weight: 2,
    opacity: 1,
    color: 'white',
    dashArray: '3',
    fillOpacity: 0.7
  }
}

const featureMap = new Map()
bay_area_city.features.forEach(f => {
  featureMap.set(f.name, f.stats)
})


const filteredCities = {
  ...incorporatedCityCA, features: incorporatedCityCA.features.filter(f => {
    return bay_area_city.features.map(f => f['name']).indexOf(f.properties.CITY.toLowerCase()) > -1
  })
}

const CA_City_With_Boundaries = ({ priceOrGrowth }) => {
  const anchorPosition = [37.5488, -121.9683]

  const geoRef = useRef()
  const [cityFeature, setCityFeature] = useState(null)

  const resetHighlight = (geo, layer) => {
    geo.current.resetStyle(layer)
    setCityFeature(null)
  }
  const highlightFeature = (e, feature) => {
    const layer = e.target

    layer.setStyle({
      weight: 5,
      color: '#6a1f6e',
      dashArray: '',
      fillOpacity: 0.7
    })
    layer.bringToFront()
    setCityFeature(feature)
  }

  const inter = (feature, layer) => {
    layer.on({
      mouseover: (e) => highlightFeature(e, feature),
      mouseout: () => resetHighlight(geoRef, layer)
    })
  }


  return (
    <div className={'w3-card w3-padding-left'}>
      <div className={'leaflet-map-container'}>
        <MapContainer center={anchorPosition} zoom={9} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <GeoJSON ref={geoRef} key={'my-geo'} data={filteredCities}
                   style={priceOrGrowth === 1 ? medianPriceDecor : priceGrowthDecor}
                   onEachFeature={inter} />
          {priceOrGrowth === 1 &&
            <CityInfoPanel>
              <CityMedianPriceInfo feature={cityFeature} featureMap={featureMap} />
            </CityInfoPanel>
          }
          {priceOrGrowth === 2 &&
            <CityInfoPanel>
              <CityPriceGrowthInfo feature={cityFeature} featureMap={featureMap} />
            </CityInfoPanel>
          }
        </MapContainer>
      </div>
    </div>
  )
}

export { CA_City_With_Boundaries, GrowthLegend, PriceLegend }