import axios from 'axios'

const baseUrl = 'https://api.totheloners.com'
// const baseUrl = 'http://127.0.0.1:5000'

const logVisit = () => {
  return axios.post(`${baseUrl}/monitor/visit`)
}

const basicCelery = () => {
  return axios.post(`${baseUrl}/tasks/basic`, {})
}

const uploadFile = (body) => {
  return axios.post(`${baseUrl}/files/uploads`, body)
}

const downloadFile = (filename) => {
  return axios.get(`${baseUrl}/files/downloads/${filename}`, { responseType: 'blob' })
}

export default { logVisit, basicCelery, uploadFile, downloadFile }