import React from 'react'
import { Link } from 'react-router-dom'

const parseEssay = (essay) => {
  return essay.split('\n')
}

const Paragraph = (props) => {
  return (
    <>
      <p className="c2">
              <span className="c0">
                  {props.text}
              </span>
      </p>
      <p className="c1">
        <span className="c0" />
      </p>
    </>
  )
}

export { Paragraph, parseEssay }
