import React from 'react'
import CommentBoard from '../components/Comment'
import { Paragraph, parseEssay } from './utils'
import Header from '../components/Header'

const essay =
  '搬来湾区五年有余，如果说这几年要用一件事来总结的话，那就是找女朋友。并不是我这几年一直在没日没夜地找对象，而是发现做的很多事情最后都指向了找对象这个终极目标。小至周末参加社交活动认识女生，大到炒股跳槽提升自己，都可以贴上为脱单服务的标签。毕竟巴菲特也曾说过，和对的人结婚比生活中其它事情都重要。股神都这么说了，那重点好好找对象也不丢人。按理说湾区这个阳光充足，海岸线悠长，也不乏优秀男女的地方，应当是具备了恋爱的天时地利人和。但是现实是骨感的，兜兜转转几年，自己还是单着。在这里记录一下这几年来的一些观察和感想，希望多年之后回过头来看当年脱单路上的挣扎，可以一笑之。\n' +
  '\n' +
  '当时从纽约搬来湾区之前朋友就揶揄说，纽约这么多女生你都没找到女朋友，湾区僧多粥少你就往坑里跳吧。果不其然刚来湾区就感受到了坑里强烈的雄性气息。不管是在公司组里九比一的男女同事比例，还是微信周末活动群里清一色的蓝色性别标记，都在传递着一个信号：湾区的男生就该单着。但是随着社交圈的拓展，我意外地发现其实湾区的女生也不少。在和接触到的女生聊天过程中，她们很多都提到自己的生活圈子里其实大多是女生，也愁找不到男朋友。这就出现了一个有些吊诡的情况：湾区有一大堆单身男生和一大堆单身女生，这两个圈子也没有生殖隔离，但是最后他们和她们就是凑不到一起。\n' +
  '\n' +
  '为什么湾区这么多人都单着？这估计是很多人想弄明白的问题。如果你问男生们，他们会说接触不到妹子；问女生，她们会说男生质量都太差了；问爸妈，会告诉你你要求太高了；问身边的闺蜜，她们会说适合你的那个人还没出现；如果你问CMB，那答案是因为你不舍得花钱买会员。俗话说，一千次失败的约会，有两千个失败的理由。从个体的角度来说，对方不合适的理由的确是见仁见智（详情可参见小红书《奇葩约会对象实录》《渣男辨别指南》等热帖）。但是如果把众多单身男女当成一个整体来看，却又可以发现一些规律的蛛丝马迹。\n' +
  '\n' +
  '如果说恋爱是上层建筑，那么决定恋爱市场风向的则是其底层的婚姻观。恋爱过程中的层层迷雾，放到婚姻的层面来看，可能就会清晰很多。在二十一世纪的今天，无论是西方还是东方社会，婚姻的社会地位其实是在逐步下降的。在人类历史进程的前几千年中，婚姻所提供的家庭结构在社会生产力发展的过程中扮演者举足轻重的作用。但是随着资本社会的进化，家庭结构所提供的生产价值开始瓦解。一方面劳动力逐渐回归到以个人为基本单位，而不再是家庭（单身狗显然更受资本家们青睐）；另一方面，随着社会生活成本的提高，成立家庭以及生育所带来的经济压力，让婚姻在很多时候从1+1大于2变成了减法。越来越多的人开始意识到除了婚姻，人生还有事业，个人价值的实现，以及独立和潇洒。对于越来越多的单身男女来说，结婚不再是生活中的优先级，甚至不婚也可以是一种选择。而对于那些仍然很渴望走进围城的单身一族来说，这些不紧不慢或者其实是独身主义的“伪单身”，反倒成为了脱单之路上的拦路虎。不论对方多么优秀，年龄多么合适，如果婚姻观不同，到最后也只是徒增伤心罢了。单身的人很多，真正想好好找对象的数量却要大打折扣。婚恋市场是一个零和游戏，每一个“伪单身”的退市，都意味着会有另外一个好单身也必须单着，这就是一个无奈的现实。\n' +
  '\n' +
  '相信大多数单身一族都被问到过这样一个问题，“你想找一个什么样的人”？这个问题隔壁热心大妈问过，身边好友问过，CMB也问过，但是对于单身狗们来说，这个问题的难度其实不亚于人生三大问题的灵魂拷问。说到底，找一个人生伴侣是为了有一个更好的人生，但是当自己的人生孰重孰轻都还没想明白的时候，什么样的伴侣最适合自己自然也无从谈起。甜甜的校园的山盟海誓和接近半数的离婚率并不矛盾，因为随着时间推移，人会成长会变，人性也比我们想的要复杂。而在物质和精神世界都日益丰富的今天，社交媒体和资本更是把人的欲望推向了顶峰。上学的时候奈克告诉我们“Just do it“，成年了之后奔驰告诉我们”The best, or nothing“。美国的个人英雄主义教会了我们如何变成最好的自己，却没有告诉我们如何取舍。总有一天，我们会需要在钱和发际线两者之间做选择。既要又要还要固然很霸气，但其实是选择了不做选择。现实往往不如小红书里描绘的那么美好，并不一定会嘉奖每一个执着的人。\n' +
  '\n' +
  '除开伪单身和理想派，想好好找对象的单身人群仍然是大多数。她们态度诚恳，也明白自己想要什么，并且择偶的标准也很合理。甚至有不少女生的要求只是找一个“正常的男生”。这一点倒困扰了我很久，为何在湾区女生眼里，这么多我觉得还挺正常的男生对她们来说都不太“正常”？我想，从某种角度来说，不正常的可能不是个体，而是我们这个时代。如果说婚姻的本质在于价值交换，那么在传统的择偶原则里，等式左边是男生的物质基础，等式右边是女生的贤惠持家。这个模式当然不能保证每一段婚姻的幸福，但自古以来一直都是最简单高效的搭配方式。不过随着女性劳动力的解放，女权意识的觉醒，以及科技的发展和社会的进步，男性的天然相对优势在二十一世纪正被逐步蚕食。挣钱不再是男人的专利，换电灯泡也不是，甚至生育下一代也不是（可参见小红书《去父留子》话题讨论）。这是人类文明向前迈的表现，但也意味着两性之间的结合又少了一个强有力的推手。男生开始不习惯坐在对面的女生和自己一样有事业心，女生也惊讶地发现自己的优秀竟然会成为脱单路上一把无形的枷锁。对于越来越多的人来说，两性之间差距的缩小以及角色的转变让男强女弱这个根深蒂固的择偶原则不再好用。永远稀缺的高富帅自然不受影响，但是对于处于社会主体的中产阶级男性来说，不管他们愿不愿意承认，他们所提供的物质基础已经不足以成为吸引异性的源动力了。\n' +
  '\n' +
  '没有了物质基础，家庭传统，生育使命这些客观因素，那到底还剩下什么能让两个人打破舒适的单身生活而走到一起？恐怕要靠爱情了。大概是因为太奢侈，现在已经很少听到有人说自己要寻找爱情。取而代之的是诸如感觉对，聊得来，三观符合，情绪稳定，或者相处舒服等等描述。不过爱情到底长什么样子，谁又说得准？只要是喜欢，没有将就的感觉，那就通通可以算爱情。如果婚姻的本质真的回归了爱情，也不知道到底是好事还是坏事。我们的先人是不是早就洞察到了爱情是小概率事件，可遇不可求，才花了那么多心思让婚姻绕开爱情。黑格尔说人类历史的意义在于思想的不断自由和进步，我说历史的意义在于单身狗总能在新环境中找到方式脱单。希望男生们继续保持雄风，不管眼前如何困难重重，还是能够拨开迷雾，“正常”一点勇敢地迈出那一步。在这个日新月异的世界里，还是能让自己钟意的姑娘愿意放下手机，放下干不完的工作，放下独立自由肆意的潇洒生活状态，抛开现代社会的新枷锁，来到你的对面坐下，托着下巴红着脸听你胡吹。\n' +
  '\n'

export default function Dating({ post_id }) {
  let data = parseEssay(essay)
  return (
    <>
      <Header />

      <div className="w3-margin-top w3-row-padding article">
        <div className="w3-twothird w3-margin-bottom">
          <h3>湾区闲聊之找对象</h3>
          <blockquote className="w3-panel w3-leftbar w3-light-grey">
            <p className="w3-small">
              <i>"A relationship, I think, is like a shark. You know? It has to constantly move forward or it dies."</i>
            </p>
            <p className="w3-small">Annie Hall</p>
          </blockquote>
          {data.filter(v => v.length > 0).map((v, i) => {
            return <Paragraph text={v} key={i} />
          })}
        </div>
        <div className="w3-margin-top w3-twothird w3-card-2 w3-margin-bottom w3-padding">
          <CommentBoard post_id={post_id} />
        </div>
      </div>
    </>
  )
}