import React, { useRef, useState } from 'react'
import { Paragraph, parseEssay } from './utils'
import Header from '../components/Header'

const p1 = '美国第一人口大州，世界第五大经济体，硅谷，好莱坞。世界上大概很难有第二个地方像加州这样集万千宠爱于一身。今天我们来快速了解一下加州的前世今生。'
const p2 = '加里福尼亚的早期历史和其它北美地区类似，印第安原住民过了几千年岁月静好的日子，结果欧洲鬼子来了。因为地处太平洋和美洲的交界，加州很快被西方殖民势力定位成连通美洲和亚洲的环球航线重要中转站。在18世纪末被开发之后，各派势力在加州这块肥地竞相交手。西班牙先入为主，俄罗斯法国英国也设法分一杯羹，虽然墨西哥短暂上位，但最后还是被美国笑纳囊中。'
const p3 = '十九世纪加州见证了旧金山的崛起，那么二十世纪则轮到了洛杉矶。1900年的洛杉矶还只是一个只有十万人口的小镇，但是接下来的三板斧迅速让她和旧金山一起成为加州的南北双雄。首先是水利工程的创新。在科罗拉多河被引入南加的水库之后，南加的农业生产力迅速被解放，成为地区经济的重要基石。南加的阳光和美景本来的主要贡献是吸引疗养和养老的游客，但是东海岸的电影人很快发现这对电影拍摄来说同样是重要资源。电影这个行业刚在东海岸起步，好莱坞就杀出来了。三板斧的最后一块拼图估计会让大家有些意外：航空业。洛杉矶现在给大家的印象就是比佛利红地毯，但是当年还是很蓝领踏实做实业的。虽然怀特兄弟在北卡宣告了飞机时代的来临，但是在第一个大力发展航空业的却是加州。在这里诞生了洛克希德，诺斯罗普等航空巨头，也把洛杉矶的工业体系推上了新的台阶。当然，北边的湾区也这个时候也没闲着。斯坦福首推学校背书的工业创新园，而第一家入住的公司就是惠普。那时的湾区还不叫硅谷，但是当时的湾区已经在全美电子产业开始扮演领头羊的角色。'
const p4 = '建州时的加州靠着海滩和金子闯入了美国人的视线，而在二十世纪加州则顺利地坐上了美国各州的头把交椅。首先是二战的到来一下子让加州成为了太平洋战场的急先锋。数以千万计的男男女女从全国各地来到旧金山和圣地亚哥中转，奔向前线。在战后，许多退役老兵也选择在加州成家立业。与此同时，联邦政府在加州大力发展军工和制造业，也拉动了州内基建的迅速扩张。工作岗位增加，来加州打工的人接踵而至，服务业也蓬勃发展，继续扩大劳动力需求，教科书级的经济上升周期良性循环。在1964年，加州的总人口来到一千八百万，正式超过纽约州，为全美第一。紧接着在六十年代湾区又迎来了半导体行业的诞生，从此摇身一变成为了硅谷，让加州在美国经济的首席地位牢不可破。'
const p5 = '回顾加州历史，不得不感叹她一路走来的天时地利人和。从地利上来说，其作为美国在太平洋的门户，天生就自带重要战略地位；而四季的阳光，温暖的气候，以及漫长的海岸线则为加州打造了靓丽的自然风光和舒适的居住环境，既奠定了农业大州的基础，又吸引着无数人才的到来。从天时上来说，加州出生就是矿一代；虽然离彼时作为美国经济主体的东海岸隔着十万八千里，但是赶上了铁路运输兴起的红利，丝毫没耽误挣钞票；在二十世纪又分别赶上了航空业，电影业，和电子信息业的浪潮，也是吃到了美国在二战中最大的经济红利；可以说，加州自成立一来就踩着时代的脉搏一路狂奔，让人感叹牛人就是牛在运气也最好。最后是人和。加州自建州以来就是全球种族大熔炉。1870年的跨大陆铁路的背后是无数中国劳工的血和汗；加州强大的农业其实也离不开其背后廉价的墨西哥劳动力；好莱坞创立初期左手吸引到了东岸最顶尖的电影制作班底，右手接手了当时欧洲几乎所有被迫害的犹太裔演艺人才（例如比尔怀尔德）；互联网时代汇聚了全球各地的顶尖科技人才自是不用提。到了今天，加州已经成为种族多元化的一个全球标杆。来到这里的男男女女在加州找到新的人生，他们也塑造了加州。现在加州再一次站在人工智能的风口浪尖，不知道又会谱写什么样的历史新篇章。不过可以确定的是，不管再经历什么样的辉煌，挣扎和自我蜕变，加州在人们心里都会像那本中世纪神话小说里写的小岛一样：美丽，充满希望。'
const timeline =
  '1510 - 加利福尼亚这个名字第一次出现是在一本16世纪的西班牙小说里。在书中加利福尼亚是一个美丽的在伊甸园附近的小岛，被一个名叫加拉菲亚的女王统治。\n' +
  '1542 - 西班牙探险家Cabrillo首次沿加州海岸线航行。\n' +
  '1577 - 英国探险家Drake登陆北加（于现今Point Reyes的位置）。\n' +
  '1769 - 西班牙派遣一支由传教士和军队组成的探险队由墨西哥出发北上开发加利福尼亚。传教士Junipero Serra在沿途建立的早期教会据点日后成为了加州的各大主要城市。\n' +
  '1775 - 西班牙Portola将军首次通过金门进入旧金山湾区。\n' +
  '1777 - 圣何塞成为首个殖民据点。\n' +
  '1781 - 洛杉矶成为第二个殖民据点。\n' +
  '1824 - 加利福尼亚成为新独立的墨西哥共和国的土地。\n' +
  '1834 - 美国人开始定居在加利福尼亚。\n' +
  '1846 - 美国陆军上尉Fremont率军挑唆加利福尼亚的叛乱，在山顶竖起美国国旗。\n' +
  '1848 - 美墨战争结束，休战协议中加里福尼亚被划为美国领土。黄金在萨克拉门托地区被发现，开启淘金潮。\n' +
  '1849 - 加利福尼亚首届立法会召开。\n' +
  '1850 - 加州正式成为美国第三十一个州。\n' +
  '1860 - 工程师Judah向国会提议建造一条联通加州和东岸的跨大陆铁路。\n' +
  '1868 - 加州大学系统建立。伯克利为第一个UC校园。（加州第一所高校是1851年建立de Santa Clara University）。\n' +
  '1869 - 第一条跨大陆铁路竣工，连通萨克拉门托（加州）和奥马哈（内布拉斯加州）。1871 - 洛杉矶华人屠杀案中18名中国男性被美国白人吊死。 \n' +
  '1880 - 南加州大学在洛杉矶成立。\n' +
  '1882 - 移民排华法案通过，禁止中国劳工移民美国，为美国历史上首个针对特定族裔的歧视性法案。\n' +
  '1883 - 工程师Montgomery制造出历史上首个滑翔机。\n' +
  '1885 - 斯坦福建校。\n' +
  '1905 - 科罗拉多河被引入南加州，成为了南加农业发展的重要基础。\n' +
  '1906 - 旧金山发生8.3级大地震。\n' +
  '1907 - 电影产业进入洛杉矶。\n' +
  '1911 - 洛克希德兄弟开始设计并生产海上载人飞机。\n' +
  '1912 - 工程师Forest设计早期真空管，奠定加州在电子产业的重要地位。\n' +
  '1913 - 针对日裔的置地法案通过，禁止移民取得土地拥有权。\n' +
  '1924 - 排日移民法案通过。\n' +
  '1930 - 八千名墨西哥裔农业劳工因不公待遇罢工。\n' +
  '1936 - Bay Bridge建成。\n' +
  '1937 - 金门大桥建成。\n' +
  '1942 - 由于珍珠港的爆发，罗斯福签署总统令囚禁加州以及西海岸的日裔，防止通敌叛国。\n' +
  '1955 - 迪士尼乐园开张。\n' +
  '1956 - 跨掉的一代运动开始。\n' +
  '1959 - 仙童半导体公司成立。\n' +
  '1964 - 伯克利校园反战游行爆发。\n' +
  '1976 - 苹果个人电脑问世。\n' +
  '1978 - 加州历史上大名鼎鼎的Proposition 13通过，把房产税的计算和购入价绑定。\n' +
  '1989 - 圣塔克鲁兹6.9级大地震，66人死亡，约七十亿美元经济损失。\n' +
  '1992 - 洛杉矶大暴乱。韩裔和黑人以及拉丁裔的枪战动乱造成了让韩国城几乎成为废墟。\n' +
  '1994 - 洛杉矶6.7级大地震，57人死亡，约200亿美元经济损失。\n' +
  '2003 - 施瓦辛格当选加州州长。'

export default function CaliHistory({ post_id }) {
  let bulletPoints = parseEssay(timeline)
  return (
    <>
      <Header />

      <div className="w3-margin-top w3-row-padding article">
        <div className="w3-twothird w3-margin-bottom">
          <h3>加州闲聊之历史</h3>
          <blockquote className="w3-panel w3-leftbar w3-light-grey">
            <p className="w3-small">
              <i>"To live and die in L.A., it's the place to be."</i></p>
            <p className="w3-small">2Pac</p>
          </blockquote>
          <Paragraph key={1}
                     text={p1} />
          <ul>
            {bulletPoints.map((v, i) => {
              return <li key={i}>{v}</li>
            })}
          </ul>
          <Paragraph key={2}
                     text={p2} />
          <Paragraph key={3}
                     text={p3} />
          <Paragraph key={4}
                     text={p4} />
          <Paragraph key={5}
                     text={p5} />
        </div>
      </div>
    </>
  )
}