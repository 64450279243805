import axios from 'axios'

const baseUrl = process.env['REACT_APP_USER_BASE_URL']

const login = (credentials) => {
  return axios.post(baseUrl + '/login', credentials)
}

const logout = () => {
  let headers = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`
    }
  }
  return axios.post(baseUrl + '/logout', null, headers)
}

export default { login, logout }