import bay_area_city from '../../assets/json/bay_area_city_population.json'
import cityCA from '../../assets/json/California_Cities.json'
import L from 'leaflet'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'
import chroma from 'chroma-js'
import './graphs.css'

const CityInfoPanel = () => {
  return (
    <div className={'leaflet-top leaflet-right'}>
      <div className="leaflet-control leaflet-bar leaflet-info-panel population-county-graph-info-panel">
        <p>详情点击城市标签</p>
      </div>
    </div>
  )
}

const featureMap = new Map()
bay_area_city.features.forEach(f => featureMap.set(f.name, f.stats))
const bay_area_city_list = bay_area_city.features.map(f => f['name'])
const filteredCities = {
  ...cityCA, features: cityCA.features.filter(f => {
    return bay_area_city_list.indexOf(f.properties.NAME.toLowerCase()) > -1
  })
}

const Population_CA_City = () => {

  const anchorPosition = [37.7411, -122.1755]

  const inter = (feature, layer) => {
    layer.on('mouseover', function(e) {
      layer.bindPopup(assetbleCityInfo(feature)).openPopup()
    })
  }

  const assetbleCityInfo = (feature) => {
    const placeName = feature.properties.NAME
    const population = featureMap.get(placeName.toLowerCase())['POPULATION']
    const foreignBorn = featureMap.get(placeName.toLowerCase())['FOREIGN-BORN POPULATION']
    const asian = featureMap.get(placeName.toLowerCase())['DEMOGRAPHICS']['ASIAN']
    const white = featureMap.get(placeName.toLowerCase())['DEMOGRAPHICS']['WHITE']
    const hispanic = featureMap.get(placeName.toLowerCase())['DEMOGRAPHICS']['HISPANIC']
    const black = featureMap.get(placeName.toLowerCase())['DEMOGRAPHICS']['BLACK']
    return `
        <Popup>
                <p>
          <b>${placeName}</b> <br />
          人口: ${population} <br />
          移民占比: ${foreignBorn} <br />
          亚裔: ${asian} <br />
          白人: ${white} <br />
          拉丁裔: ${hispanic} <br />
          非洲裔: ${black} <br />
        </p>
      </Popup>`
  }

  const getCityColor = (feature) => {
    const placeName = feature.properties.NAME
    const percentage = parseFloat(featureMap.get(placeName.toLowerCase())['DEMOGRAPHICS']['ASIAN']) / 100
    const scale = chroma.scale(['#fafa6e', '#2A4858'])
    const color = scale(percentage).hex()
    return { color: color }
  }

  const convertToCircleMarker = (feature, latLang) => {
    const circle = L.circleMarker(latLang, { radius: 4 })
    circle.setStyle(getCityColor(feature))
    return circle
  }


  return (
    <div className={'leaflet-map-container'}>
      <MapContainer center={anchorPosition} zoom={9} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON key={'my-geo'} data={filteredCities} onEachFeature={inter} />
      </MapContainer>
    </div>
  )
}

export { Population_CA_City }